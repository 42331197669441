$light-black: #2E3133;
$light-gray: #F2F4F7;
$medium-gray: #D2D4D6;
$normal-gray: #828385;
$dark-gray: #4E555B;
$primary-color: #005EB8;
$secondary-color: #AB206C;
$success-color: #3EAE3E;
$danger-color: #F00000;
$info-color: #F36C3D;
$button-light-color: white;
$button-light-hover-color: #F8DD61;
$book-car-cta-button-color: #F8DD61;
$book-car-cta-button-text-color: $danger-color;

$primary-button-text-color: white;
$link-color: $primary-color;
$header-button-background-color: #286EC0;
$header-border-bottom: none;
$top-nav-background-color: $primary-color;
$top-nav-open-background-color: $primary-color;
$top-nav-active-border: #F8DD61;
$top-nav-link-color: white;
$top-nav-outline-button-outline: $button-light-color;
$top-nav-outline-button-outline-hover: $button-light-hover-color;
$top-nav-outline-button-color: $button-light-color;
$top-nav-outline-button-color-hover: $button-light-hover-color;

$bottom-nav-background-color: $light-gray;
$bottom-nav-active-border: #F36C3D;
$bottom-nav-link-color: $light-black;
$bottom-nav-link-hover-color: #000;

$nav-burger-color: white;
$nav-burger-background: $header-button-background-color;
$nav-burger-border: none;
$nav-burger-open-color: white;
$nav-burger-open-background: $header-button-background-color;

$mobile-nav-background-color: $top-nav-background-color;
$mobile-nav-link-color: $top-nav-link-color;
$mobile-nav-active-background-color: $mobile-nav-link-color;
$mobile-nav-active-link-color: $mobile-nav-background-color;

$font-family-main: 'Avenir Next', sans-serif;
$font-family-headings: 'TTRounds-Black', sans-serif;

$footer-bottom-section-background-color: white;
$footer-bottom-section-text-color: $dark-gray;
$footer-background-color: $primary-color;
$footer-text-color: white;
$footer-link-color: #F8DE60;

$box-shadow: 0 0 8px 3px rgba(0,0,0,0.1);

$rating-background: $button-light-hover-color;
$rating-color: $danger-color;

$columns-with-background: $light-gray;

$plus-icon-color: #ee2249;

h1, h2, h3, h4 {
  font-weight: normal !important;
  text-transform: uppercase;
  span {
    text-transform: none;
  }  
}

.booking-widget__title {
  h2 {
    color:$primary-color !important;
    font-size: 36px
  }
}
